
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './pages/home/Home';
import Price from './pages/price/Price';
import Contact from './pages/contact/Contact';
import Header from './comps/header/Header';
import WithContentEditable from './comps/content editable/WithContentEditable';
import Footer from "./comps/footer/Footer"
import pricing from "./pages/pricing_Form/Pricing_Form"
import Pricing_Form from './pages/pricing_Form/Pricing_Form';
import Privacy from './pages/privacy/Privacy';
import PageToTop from './pages/pagetotop/PageToTop';
import { useState } from 'react';
import Thank_You_Page from './pages/thank_you_page/Thank_You_Page';




function App() {

  const [packDetail, setPackDetail] = useState(1);
  const [duration, setDuration] = useState(0);
  const [masterData, setMasterData] = useState([]);



  return (
    <div className="App">
      <BrowserRouter>
    
      <Header/>
      <PageToTop/>
      
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/pricing' element={<Price   setPackDetail={setPackDetail} setDuration={setDuration} setMasterData={setMasterData} />}/>
        <Route path='/contact' element={<Contact/>}/>      
        <Route path='/pricing_form' element={<Pricing_Form  packDetail={packDetail} duration={duration}  masterData={ masterData}/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
        <Route path='/complete_Payment' element={<Thank_You_Page/>}/>
      </Routes>
      <Footer />
      </BrowserRouter>
     
    </div>
  );
}

export default App;

// export default WithContentEditable(App);
