import React, { useEffect, useState } from "react";
import "./pricing_form.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "antd";
import Razorpay from "razorpay";
import { TiTick } from "react-icons/ti";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";
const Pricing_Form = ({ packDetail, duration, masterData }) => {
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectStateId, setSelectStateId] = useState("");
  const [selectDistrictId, setSelectDistrictId] = useState("");
  const [selectCitytId, setSelectCityId] = useState("");

  console.log(selectedCountryId, "country");
  // formdata inputs

  const [regData, setRegData] = useState({
    organizationName: "",
    unitName: "",
    countryId: "",
    cityId: "",
    districtId: "",
    stateId: "",
    areaId: "",
    postalCode: "",
    appUrl: "",
    address: "",
    subDomain: "",
    contactNumber: "",
    organizationEmailId: "",
    appId: packDetail,
    emailId: "",
    contactPerson: "",
    paymentDetails: {
      amount:
        (masterData &&
          masterData[packDetail - 1] &&
          masterData[packDetail - 1].price[duration]) ||
        0,
      TransactionId: "",
      planType:
        duration === 0
          ? "1 month"
          : "" || duration === 1
          ? "6 months"
          : "" || duration === 2
          ? "12 months"
          : "",
    },
  });

  const [options, setOptions] = useState([]);
  const [lastQueriedSubDomain, setLastQueriedSubDomain] = useState("");

  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  // Set default values based on the country/state/city selected
  useEffect(() => {
    setRegData((prevData) => ({
      ...prevData,
      unitName: prevData.organizationName,
      appUrl: prevData.subDomain,
      countryId: selectedCountryId,
      cityId: selectCitytId,
      districtId: selectDistrictId,
      stateId: selectStateId,
    }));
  }, [
    regData.organizationName,
    regData.subDomain,
    selectedCountryId,
    selectCitytId,
    selectDistrictId,
    selectStateId,
  ]);

  useEffect(() => {
    if (masterData.length === 0) {
      navigate("/pricing");
    }
  }, [masterData, navigate]);

  const handleSearch = (value) => {
    if (!value || value.includes("@")) {
      setOptions([]);
      return;
    }

    setOptions((prevOptions) =>
      prevOptions.map((domain) => ({
        label: `${value}@${domain}`,
        value: `${value}@${domain}`,
      }))
    );
  };

  const fetchSubDomains = async () => {
    try {
      const sanitizedName = regData.organizationName
        ?.trim()
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "");

      const abbreviations = sanitizedName ? sanitizedName.split(" ") : [];
      const generatedShortName = abbreviations.map((word) => word[0]).join("");
      const generatedShortNameAlt = sanitizedName?.substring(0, 6);

      const suggestedSubDomains = [
        `${generatedShortNameAlt}.his.axonichealth.co.in`,
      ];

      const selectItem = regData.subDomain || suggestedSubDomains[0];

      // Avoid redundant calls for the same subdomain
      if (selectItem === lastQueriedSubDomain) return;

      // Set the current value to avoid redundant calls
      setLastQueriedSubDomain(selectItem);

      const response = await axios.get(
        `https://dns.google/resolve?name=${selectItem}`
      );

      if (
        response.data &&
        response.data.Answer && // Use `Answer` to check for DNS records
        response.data.Answer.length > 0
      ) {
        // The subdomain is taken if there are DNS records (e.g., A or CNAME)
        setOptions(
          response.data.Answer.map((item) => ({
            label: item.name.slice(0, -1), // Display both the name and data
            value: item.name.slice(0, -1), // Value as the name of the DNS record
          }))
        );
      } else {
        // The subdomain is available
        setOptions([
          {
            label: `${selectItem} - Available`,
            value: selectItem,
          },
        ]);
      }
    } catch (error) {
      console.error("Error fetching subdomains:", error);
    }
  };

  const debouncedFetchSubDomains = debounce(fetchSubDomains, 500);

  useEffect(() => {
    if (regData.organizationName || regData.subDomain) {
      debouncedFetchSubDomains();
    }
  }, [regData.organizationName, regData.subDomain]);

  const fetchCountryList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getActiveCountryList/0`
      );
      setCountry(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStateList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getActiveStateListByCountryId`,
        {
          countryId: selectedCountryId,
        }
      );
      setStates(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrictList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getActiveDistrictListByStateId`,
        {
          stateId: selectStateId,
        }
      );
      setDistrict(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCityList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getActiveCityListByDistrictId`,
        {
          districtId: selectDistrictId,
        }
      );
      setCity(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAreaList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/getAreaByCityId`,
        {
          cityId: selectCitytId,
        }
      );
      setArea(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (selectedCountryId) fetchStateList();
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectStateId) fetchDistrictList();
  }, [selectStateId]);

  useEffect(() => {
    if (selectDistrictId) fetchCityList();
  }, [selectDistrictId]);

  useEffect(() => {
    if (selectCitytId) fetchAreaList();
  }, [selectCitytId]);

  const handlePaymentSuccess = (response) => {
    const transactionId = response.razorpay_payment_id;

    const updatedRegData = {
      ...regData,
      paymentDetails: {
        ...regData.paymentDetails,
        TransactionId: transactionId,
        amount: regData.paymentDetails.amount,
      },
    };

    setRegData(updatedRegData);

    setTimeout(() => {
      regDataTransfer(updatedRegData);
    }, 500);
  };

  const regDataTransfer = async (updatedRegData) => {
    try {
      const stringifiedPaymentDetails = JSON.stringify(
        updatedRegData.paymentDetails
      );

      const payload = {
        ...updatedRegData,
        paymentDetails: stringifiedPaymentDetails,
      };

      const response = await axios.post(
        "http://subscription.axonichealth.co.in/api/createOrganizationFromWeb",
        payload
      );
      if (response.data.status === "success") {
        setRegData({
          organizationName: "",
          unitName: "",
          countryId: "",
          cityId: "",
          districtId: "",
          stateId: "",
          areaId: "",
          postalCode: "",
          appUrl: "",
          address: "",
          subDomain: "",
          contactNumber: "",
          organizationEmailId: "",

          emailId: "",
          contactPerson: "",
        });

        navigate("/complete_Payment");
      }
      console.log(response, "form submiitted");
    } catch (error) {
      console.log(error);
    }
  };

  const initiatePayment = () => {
    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay SDK not loaded");
      return;
    }

    const options = {
      key: "rzp_live_7qfAOwTzkoJ612",
      amount: regData.paymentDetails.amount * 100,
      currency: "USD",
      name: regData.organizationName, // Organization name
      description: "Test Transaction", // Description for the transaction
      handler: handlePaymentSuccess, // Callback function for payment success
      prefill: {
        name: regData.contactPerson,
        email: regData.organizationEmailId,
        contact: regData.contactNumber,
      },
      theme: { color: "#3399cc" },
    };

    // Open Razorpay payment modal
    const rzp = new window.Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", function (response) {
      console.log("Payment Failed:", response.error);
      alert("Payment Failed! " + response.error.description);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    initiatePayment();
  };

  return (
    <>
      <div class="popup-form-parent parent">
        {masterData.length > 0 && (
          <div class="popup-form-cont container">
            <div className="pricing-box">
              <div className="content">
                <h1>${masterData[packDetail - 1].price[duration]}</h1>
                <h2>
                  <span> {masterData[packDetail - 1].name}</span>
                </h2>
                <p>
                  <strong>
                    {duration === 0
                      ? "Monthly"
                      : duration === 1
                      ? "6 Months"
                      : duration === 2
                      ? "Yearly"
                      : null}
                  </strong>
                </p>
              </div>

              <div className="price">
                <h2>${masterData[packDetail - 1].price[duration]}</h2>
              </div>

              <div className="action">
                <Link className="btn" to="/pricing">
                  Change Plan
                </Link>
              </div>
            </div>
            <div class="terms-condition">
              <p>
                {" "}
                No. of hours of virtual training :{" "}
                <span>{masterData[packDetail - 1].traing_duration} </span>
              </p>
              <p>
                {" "}
                Configuration cost :{" "}
                <span>{masterData[packDetail - 1].configuration_cost}</span>
              </p>
              <p>
                {" "}
                Training cost :{" "}
                <span>{masterData[packDetail - 1].training_cost} </span>
              </p>
              <p>
                {" "}
                <span>Email support :</span> Free
              </p>
            </div>
            <h3>Please fill the form below to get to the payment page.</h3>

            <form action="" onSubmit={handleFormSubmit}>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.organizationName}
                  required
                  onChange={(e) =>
                    setRegData({ ...regData, organizationName: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.organizationName !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Organization Name
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <AutoComplete
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    padding: "0px",
                    fontSize: "16px",
                    border: "none",
                    outline: "none",
                  }}
                  onSearch={handleSearch}
                  options={options}
                  className="form-input autocomplete"
                  type="email"
                  required
                  value={regData.subDomain}
                  onChange={(value) =>
                    setRegData({ ...regData, subDomain: value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.subDomain !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Application URL
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="email"
                  className="form-input"
                  value={regData.organizationEmailId}
                  required
                  onChange={(e) =>
                    setRegData({
                      ...regData,
                      organizationEmailId: e.target.value,
                    })
                  }
                />
                <label
                  for=""
                  className={
                    regData.organizationEmailId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Organization Email-id
                </label>
              </div>

              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.contactPerson}
                  required
                  onChange={(e) =>
                    setRegData({ ...regData, contactPerson: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.contactPerson !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Contact Person Name
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="tel"
                  className="form-input"
                  value={regData.contactNumber}
                  required
                  maxLength="10"
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numbers and limit to 10 characters
                    if (/^\d*$/.test(value)) {
                      setRegData({ ...regData, contactNumber: value });
                    }
                  }}
                />
                <label
                  for=""
                  className={
                    regData.contactNumber !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Contact Number
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="email"
                  className="form-input"
                  required
                  value={regData.emailId}
                  onChange={(e) =>
                    setRegData({ ...regData, emailId: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.emailId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Personal Email-id
                </label>
              </div>

              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  required
                  value={regData.address}
                  onChange={(e) =>
                    setRegData({
                      ...regData,
                      address: e.target.value,
                    })
                  }
                />
                <label
                  for=""
                  className={
                    regData.address !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Address
                </label>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  required
                  onChange={(e) => setSelectedCountryId(e.target.value)} // Update selectedCountryId
                  value={selectedCountryId}
                >
                  <option value="">Select Country</option>

                  {country.map((item, index) => (
                    <option key={index} value={item.countryId}>
                      {item.countryName}
                    </option>
                  ))}
                </select>

                <label
                  for=""
                  className={
                    regData.selectedCountryId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Country
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  required
                  id=""
                  className="form-input"
                  value={selectStateId}
                  onChange={(e) => setSelectStateId(e.target.value)}
                >
                  <option value="">Select State</option>
                  {states &&
                    states.map((item, key) => (
                      <option value={item.stateId} key={key}>
                        {item.stateName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectStateId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  State
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  required
                  value={selectDistrictId}
                  onChange={(e) => setSelectDistrictId(e.target.value)}
                >
                  <option value="">Select District</option>
                  {district &&
                    district.map((item, key) => (
                      <option value={item.districtId} key={key}>
                        {item.districtName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectDistrictId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  District
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  required
                  className="form-input"
                  value={selectCitytId}
                  onChange={(e) => setSelectCityId(e.target.value)}
                >
                  <option value="">Select City</option>
                  {city &&
                    city.map((item, key) => (
                      <option value={item.cityId} key={key}>
                        {item.cityName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectCitytId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  City
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  value={regData.area}
                  required
                  onChange={(e) =>
                    setRegData({ ...regData, area: e.target.value })
                  }
                >
                  <option value="">Select Area</option>
                  {area &&
                    area.map((item, key) => (
                      <option value={item.areaId} key={key}>
                        {item.areaName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.area !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Area
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  required
                  value={regData.postalCode}
                  onChange={(e) =>
                    setRegData({ ...regData, postalCode: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.postalCode !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Postal Code
                </label>
              </div>
              {/* <div class={error ? "form-row error" : "form-row"}>
                <input type="text" className="form-input" />
                <label for="" className="input_label">
                  Number Of Beds
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input type="text" className="form-input" />
                <label for="" className="input_label">
                  Number Of Users
                </label>
              </div> */}

              <div className="btn-tab">
                <button className="btn" type="submit">
                  Proceed to Payment
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Pricing_Form;
